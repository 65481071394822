import {responses} from "@/services/responses";
import Swal from "sweetalert2";
import Vue from 'vue'
import {utils} from "@/services/utils";
import {errorHandler} from "@/services/errorHandler";
import queryStringify from "qs-stringify";
import axios from "axios";
import copy from 'copy-to-clipboard';
import VueGoodTablePlugin from 'vue-good-table';
import TimeManager from "@/services/TimeManager";

window.axios = axios;
window.copy = copy;
window.dateTimeManager = TimeManager;
window.mainDB = "burntofi_chrilan";

window.subscriptionApi = `https://realtime.abc.co.zm:8443/subscription/api/`;
// window.subscriptionApi = `http://localhost:8080/subscription/api/`;

window.laravelApi = `https://chrilantech.com/LytSoftPosOnline/api/`
window.printApi = `https://realtime.abc.co.zm:8443/PrintApi/`

window.backOfficeTomcatApi = `https://realtime.abc.co.zm:8443/Lyt-SoftBackOfficeApi/`
// window.backOfficeTomcatApi = `http://localhost:8080/Lyt-SoftBackOfficeApi/`

window.franchiseTomcatApi = `https://realtime.abc.co.zm:8443/Franchise_OnlineServer/`
// window.franchiseTomcatApi = `http://localhost:8080/Franchise_OnlineServer/`

window.responses = responses;
window.errorHandler = errorHandler
window.pageOptions = {
    enabled: true,
    mode: 'pages',
    perPage: 10,
    position: 'bottom',
    perPageDropdown: [10, 100, 200],
    dropdownAllowAll: false,
    jumpFirstOrLast: true,
    pageLabel: 'page', // for 'pages' mode
};
const EventBus = new Vue()
window.EventBus = EventBus
window.log = (val) => console.log(val);
window.Swal = Swal;

import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

window.generateUUID = () => {
    let uuid = '', i, random;
    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            uuid += '-';
        }
        uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
}

window.$utils = utils;
Vue.prototype.$utils = utils;

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    showCloseButton: true,
    customClass: 'toastr',
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
window.Toast = Toast;
window.stringify = (object) => queryStringify(object);



window.openUrl = (url, target = '_blank') => window.open(url, target);
window.imagePlaceholder = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png';
