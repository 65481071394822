import { render, staticRenderFns } from "./LoginView.vue?vue&type=template&id=9ff72670&scoped=true&"
import script from "./LoginView.vue?vue&type=script&lang=js&"
export * from "./LoginView.vue?vue&type=script&lang=js&"
import style0 from "./LoginView.vue?vue&type=style&index=0&id=9ff72670&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ff72670",
  null
  
)

export default component.exports